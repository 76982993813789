import axios from 'axios';
import retry from 'retry';
import { closeLegend, closeLayerFilters } from '@actions/ui-actions';
import { closeNotifications } from '@actions/notification-actions';
import {
  MAP_DATA_FETCH_SUCCESS,
  MAP_SELECT_AREA,
  MAP_INVALIDATE_SELECT_AREA,
  MAP_SELECT_ITEM,
  MAP_CLEAR_HIGHLIGHT,
  MAP_HIGHLIGHT,
  MAP_VIEWPORT_CHANGE,
  MAP_SET_CENTER,
  MAP_SET_ZOOM,
  MAP_SET_STREET_VIEW_VISIBLE,
  MAP_SET_TYPE_ID,
  MAP_TOGGLE_TRAFFIC,
  MAP_SET_DRAWING_MODE,
  MAP_SELECT_AREA_DRAWING,
  MAP_MEASURE_ACTIVE,
  MAP_MEASURE_LENGTH,
  MAP_SET_USER_LOCATION,
  MAP_SET_LABEL_GRID,
  MAP_SET_DEV_SECRET_REQUIRED,
  MAP_SET_DEV_SECRET,
  MAP_SET_DISCLAIMER,
  SET_LINKED_ELEMENT,
  SET_LINKED_ELEMENT_ACTIVE,
  SET_404_PAGE
} from '@constants/action-types';
import { getFiltersFlat, getInitialUrlFilter } from '@selectors/filters';
import { getMapData } from '@selectors/map';
import turfCircle from '@turf/circle';
import turfArea from '@turf/area';
import { polygon } from '@turf/helpers';
import { googleMapsBoundsToBbox, bboxToGoogleMapsBounds } from '@utils/geometry-utils';
import { mapDataConfig, jsonMapDataConfig } from '@utils/map-data-config';
import { calculateLabels } from '@utils/map-labels';
import { pixelDistanceToMeters } from '@utils/map-utils';

const getSetViewportAction = (center, zoom, bbox) => ({
  type: MAP_VIEWPORT_CHANGE,
  viewport: {
    center,
    zoom,
    bbox
  }
});

export const setViewport = map => dispatch => {
  const center = map.getCenter();
  const zoom = map.getZoom();
  const bbox = googleMapsBoundsToBbox(map.getBounds());
  dispatch(getSetViewportAction(center, zoom, bbox));
};

const fetchMapDataSuccess = (id, payload, replace = false) => ({
  type: MAP_DATA_FETCH_SUCCESS,
  id,
  replace,
  payload
});

const cancelTokens = {};
const checkAndCreateCancelTokens = id => {
  if (cancelTokens[id]) {
    cancelTokens[id].cancel();
  }
  cancelTokens[id] = axios.CancelToken.source();
  return cancelTokens[id].token;
};

const parseUrl = url => {
  if (window.location.protocol === 'https:') {
    const parsedUrl = url.split(':');
    parsedUrl[0] = 'https';
    return parsedUrl.join(':');
  }
  return url;
};

export const fetchMapData = (id, url, fetchParams = {}) => (dispatch, getState) => {
  const state = getState();
  const filter = getFiltersFlat(state)[id];
  const search = window.location.search;
  let params = { ...fetchParams };
  if (search && filter.uiStyle.filterable && getInitialUrlFilter(state)) {
    const [type, value] = search.substr(1).split(',')[0].split('=');
    params = {...params, type, value};
  }

  const operation = retry.operation({
    retries: 5,
    factor: 3,
    minTimeout: 1 * 1000,
    maxTimeout: 60 * 1000,
    randomize: false
  });

  operation.attempt(async () => {
    try {
      await axios.get(
        parseUrl(url),
        {params, cancelToken: checkAndCreateCancelTokens(id), ...mapDataConfig}
      ).then(({ data }) => {
        dispatch(fetchMapDataSuccess(id, data));
      });
    } catch (thrown) {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      } else if (operation.retry(thrown)) {
        return; 
      }
    }
  });
};

export const fetchJsonMapData = (id, url, replace = false) => (dispatch, getState) => {
  axios.get(
    parseUrl(url),
    {cancelToken: checkAndCreateCancelTokens(id), ...jsonMapDataConfig}
  ).then(({ data }) => {
    const filter = getFiltersFlat(getState())[id];
    if (filter && filter.aliases) {
      const idField = filter.aliases.filter(([, label]) => label === 'id')[0];
      if (idField) {
        data.results.forEach(result => {
          result.id = result.attrs[idField[0]];
        });
      }
    }
    dispatch(fetchMapDataSuccess(id, data, replace));
  })
    .catch(thrown => {
      if (!axios.isCancel(thrown)) {
        throw thrown;
      }
    });
};

export const setLinkedElement = (layerData, data) => ({
  type: SET_LINKED_ELEMENT,
  layerData,
  data
});

export const setLinkedElementActive = () => ({
  type: SET_LINKED_ELEMENT_ACTIVE,
  active: true
});

export const setLinkedElementInactive = () => ({
  type: SET_LINKED_ELEMENT_ACTIVE,
  active: false
});

export const set404Page = () => ({
  type: SET_404_PAGE
});

export const deepLinkDataFetch = (dataTypeId, url, remoteId, mapRef, params = {}) => (dispatch, getState) => {
  const layerData = getMapData(getState())[dataTypeId];
  axios.get(
    `${url}?remote_id=${remoteId}`,
    {params, ...mapDataConfig}
  ).then(({ data }) => {
    if (!data.results.length) {
      dispatch(set404Page());
    } else {
      dispatch(fetchMapDataSuccess(dataTypeId, data));
      dispatch(setLinkedElement(layerData, data.results[0]));
      dispatch(setLinkedElementActive());
      mapRef.fitBounds(bboxToGoogleMapsBounds(data.results[0].bbox));
      dispatch(closeLayerFilters());
    }
  });
};

const selectAreaAction = (area) => ({
  type: MAP_SELECT_AREA,
  area
});

const setDrawingAreaAction = (area, size = null) => ({
  type: MAP_SELECT_AREA_DRAWING,
  area,
  size
});

const getCircle = (latLng, googleMap, pixelRadius = 20, meters = false) => ({
  lng: latLng.lng(),
  lat: latLng.lat(),
  radius: !meters ? pixelDistanceToMeters(latLng, googleMap, pixelRadius) : pixelRadius
});

export const selectArea = (latLng, googleMap, pixelRadius = 20, meters = false) => dispatch => {
  const area = getCircle(latLng, googleMap, pixelRadius, meters);
  dispatch(selectAreaAction(area));
};

export const selectDrawingArea = (latLng, googleMap, pixelRadius = 20, meters = false) => dispatch => {
  const area = getCircle(latLng, googleMap, pixelRadius, meters);
  const size = turfArea(turfCircle([area.lng, area.lat], area.radius / 1000.0));
  dispatch(setDrawingAreaAction(area, size));
};

export const selectDrawingPolygonArea = coordinates => dispatch => {
  const area = {
    coordinates: [[...coordinates.map(coord => [coord.lng(), coord.lat()]), [coordinates[0].lng(), coordinates[0].lat()]]]
  };
  const size = turfArea(polygon(area.coordinates));
  dispatch(setDrawingAreaAction(area, size));
};

const clearSelectionAction = () => ({
  type: MAP_SELECT_AREA,
  area: null
});

const clearItemSelectionAction = () => ({ type: MAP_SELECT_ITEM, item: null });

const setDrawingModeAction = mode => ({type: MAP_SET_DRAWING_MODE, mode });

export const closeAreaSelection = () => dispatch => {
  dispatch(setDrawingModeAction(''));
  dispatch(clearSelectionAction());
  dispatch(clearItemSelectionAction());
};

export const clearAreaSelection = () => (dispatch, getState) => {
  const currentMode = getState().map.drawing.mode;
  dispatch(setDrawingModeAction(currentMode));
  dispatch(clearSelectionAction());
  dispatch(clearItemSelectionAction());
};

const invalidateAreaSelectionAction = () => ({
  type: MAP_INVALIDATE_SELECT_AREA
});

export const invalidateAreaSelection = () => dispatch => {
  dispatch(invalidateAreaSelectionAction());
};

const selectItemAction = (area, index) => ({
  type: MAP_SELECT_ITEM,
  item: {area: {...area}, index}
});

export const selectItem = (area, index) => dispatch => {
  dispatch(selectItemAction(area, index));
};

export const clearItemSelection = () => dispatch => {
  dispatch(clearItemSelectionAction());
};

const clearHighlightAction = (name) => ({
  type: MAP_CLEAR_HIGHLIGHT,
  name
});

export const clearHighlight = name => dispatch => {
  dispatch(clearHighlightAction(name));
};

const setHighlightAction = (name, layerId, itemId) => ({
  type: MAP_HIGHLIGHT,
  name,
  layerId,
  itemId
});

export const setHighlight = (name, layerId, itemId) => dispatch => {
  dispatch(setHighlightAction(name, layerId, itemId));
};

const getSetCenterAction = center => ({
  type: MAP_SET_CENTER,
  center
});

export const setCenter = center => dispatch => {
  dispatch(getSetCenterAction(center));
};

const getSetZoomAction = zoom => ({
  type: MAP_SET_ZOOM,
  zoom
});

export const setZoom = zoom => dispatch => {
  dispatch(getSetZoomAction(zoom));
};

const getStreetViewVisibleAction = visible => ({
  type: MAP_SET_STREET_VIEW_VISIBLE,
  visible
});

export const setStreetViewVisible = visible => dispatch => {
  dispatch(getStreetViewVisibleAction(visible));
};

const newMapTypeId = payload => ({
  type: MAP_SET_TYPE_ID,
  payload
});

export const setMapTypeId = mapTypeId => dispatch => dispatch(newMapTypeId(mapTypeId));

export const toggleTraffic = () => dispatch => dispatch({ type: MAP_TOGGLE_TRAFFIC });

const setMeasureActiveAction = active => ({ type: MAP_MEASURE_ACTIVE, active });

export const setDrawingMode = mode => dispatch => {
  dispatch(closeLegend());
  dispatch(closeNotifications());
  dispatch(setMeasureActiveAction(false));
  dispatch(setDrawingModeAction(mode));
};

export const clearDrawingMode = () => dispatch => {
  dispatch(closeAreaSelection());
};

export const setMeasureActive = () => dispatch => {
  dispatch(closeLegend());
  dispatch(closeNotifications());
  dispatch(closeAreaSelection());
  dispatch(setMeasureActiveAction(true));
};

export const clearMeasureActive = () => dispatch => dispatch(setMeasureActiveAction(false));

export const setMeasureLength = length => dispatch => dispatch({ type: MAP_MEASURE_LENGTH, length });

export const setUserLocation = userLocation => dispatch => dispatch({ type: MAP_SET_USER_LOCATION, userLocation });

const getSetLabelGridAction = labelGrid => ({
  type: MAP_SET_LABEL_GRID, labelGrid
});

export const updateLabelGrid = (bbox, polygonData) => dispatch => {
  calculateLabels(polygonData, bbox).then(
    results => {
      dispatch(getSetLabelGridAction(results));
    },
    () => {} // Do nothing on reject
  );
};

const getSetDevSecretRequiredAction = () => ({
  type: MAP_SET_DEV_SECRET_REQUIRED
});

const getSetDevSecretAction = secret => ({
  type: MAP_SET_DEV_SECRET,
  secret
});

export const requestAuthSecret = () => dispatch => {
  dispatch(getSetDevSecretAction(null));
  dispatch(getSetDevSecretRequiredAction());
};

export const setAuthSecret = secret => dispatch => {
  dispatch(getSetDevSecretAction(secret));
};

export const closeDisclaimer = () => dispatch => {
  dispatch({type: MAP_SET_DISCLAIMER, visible: false});
};

export const openDisclaimer = () => dispatch => {
  dispatch({type: MAP_SET_DISCLAIMER, visible: true});
};
